import React, { useCallback, useContext, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Wrapper, PreserveButton } from "./Home.styled";
import ModalUpload from "./ModalUpload";
import {
  BLOCK_CHAIN_FILE_LIMIT,
  ALLOW_UPLOAD_IMAGE_EXTENSIONS,
} from "@/constants/file";
import { useSelector } from "react-redux";
import { getIsAuthenticatedSelector } from "@/state/user/selector";
import { showError } from "@/utils/toast";
import { WalletContext } from "@/contexts/wallet-context";
import IconSVG from "@/components/IconSVG";
import { CDN_URL } from "@/configs";
import Text from "@/components/Text";

import ImageList from "./ImageList";

const Home: React.FC = () => {
  const [files, setFiles] = useState<File[] | null>(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);
  const { onDisconnect, onConnect, requestBtcAddress } =
    useContext(WalletContext);

  const handleConnectWallet = async () => {
    try {
      await onConnect();
      await requestBtcAddress();
    } catch (err) {
      onDisconnect();
    }
  };

  const onChangeFile = (listFiles: File): void => {
    setFiles([listFiles]);
  };

  const onSizeError = (): void => {
    showError({
      message: `File size error, maximum file size is ${BLOCK_CHAIN_FILE_LIMIT * 1000
        }kb.`,
    });
  };

  const handlePreverseArtifact = () => {
    if (!isAuthenticated) handleConnectWallet();
    else {
      setShowUploadModal(true);
    }
  };

  const handleModalClose = (): void => {
    setShowUploadModal(false);
    setFiles(null);
  };

  useEffect(() => {
    if (files && files.length > 0) {
      setShowUploadModal(true);
    }
  }, [files]);

  const renderFileUpload = useCallback(
    (customClass: string) => {
      return (
        <FileUploader
          fileOrFiles={files}
          types={ALLOW_UPLOAD_IMAGE_EXTENSIONS}
          handleChange={onChangeFile}
          name="fileUploader"
          maxSize={BLOCK_CHAIN_FILE_LIMIT}
          onSizeError={onSizeError}
          classes={`${customClass} ${!isAuthenticated ? "hidden" : ""}`}
        />
      );
    },
    [files, isAuthenticated]
  );

  return (
    <Wrapper>
      <div className="content_left">
        <PreserveButton onClick={handlePreverseArtifact}>
          <div className="icon_block">
            <IconSVG
              src={`${CDN_URL}/icons/plus.svg`}
              maxHeight="20"
              maxWidth="20"
            />
          </div>
          <div>
            <div className="normal_text">New image</div>
          </div>
          {renderFileUpload("file-uploader")}
        </PreserveButton>
      </div>
      <div className="content_right">
        <ImageList>
          <div className="upload-block">
            <div className="file-wrapper">
              <IconSVG
                src={`${CDN_URL}/images/upload-photo.svg`}
                maxHeight="167"
                maxWidth="140"
              />
              <Text size="medium" fontWeight="medium" className="title">
                Ready to add some photos?
              </Text>
              <Text size="medium" fontWeight="medium" className="sub-text">
                Drag and drop an image. Max <b>350kb</b> each.
              </Text>
              {renderFileUpload("file-uploader2")}
            </div>
          </div>
        </ImageList>
      </div>
      <ModalUpload
        show={showUploadModal}
        handleClose={handleModalClose}
        files={files}
        setFiles={setFiles}
      />
    </Wrapper>
  );
};

export default Home;
