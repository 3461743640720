import styled, { DefaultTheme } from "styled-components";
import px2rem from "@/utils/px2rem";
import Button from "@/components/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .header_text {
    margin-bottom: ${px2rem(24)};
    font-weight: 600;
    font-size: ${px2rem(24)};
    line-height: ${px2rem(24)};
    letter-spacing: 0.01em;
    color: #000000;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: ${px2rem(34)};
    line-height: ${px2rem(4)};
    color: ${({ theme }: { theme: DefaultTheme }) => theme.white};
    padding: 0 ${px2rem(10)};
  }

  .list {
    min-height: 60vh;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    margin-top: ${px2rem(32)};
  }

  .arrow {
    width: ${px2rem(60)};
    height: ${px2rem(60)};
    background: rgba(255, 255, 255, 0.1);
    display: grid;
    place-items: center;
    border-radius: 50%;
    display: none;
    &.left {
      transform: rotate(180deg);
    }
  }

  .upload-block {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .file-wrapper {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
    }

    .title {
      margin-bottom: ${px2rem(4)};
      padding: 0;
      font-size: ${px2rem(24)};
      line-height: ${px2rem(26)};
      letter-spacing: -0.18px;
      color: #1c1c1c;
    }

    .sub-text {
      color: #898989;
    }

    .file-uploader2 {
      opacity: 0;
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  justify-items: center;
  grid-gap: ${px2rem(24)};
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ZoomControls = styled.div`
  position: absolute;
  color: white;
  z-index: 1000;
  display: flex;
  gap: ${px2rem(32)};
  font-size: 50px;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);

  .control {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
`;

export const SecretWrapper = styled.div`
  height: calc(100vh - ${px2rem(136)});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${px2rem(24)};

  @media screen and (max-width: 768px) {
    height: auto;
  }

  .secretDescription {
    font-weight: 500;
    font-size: ${px2rem(24)};
    line-height: ${px2rem(26)};
  }
`;

export const SignWalletButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${px2rem(15)} ${px2rem(40)};
  background: #1C1C1C;
  border-radius: 8px;
  color: #fff;
  font-family: var(--font-barlow);
  font-weight: 600;
  font-size: ${px2rem(16)};
  line-height: ${px2rem(26)};
`;

export { Container, Grid, ZoomControls };
