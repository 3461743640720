import px2rem from "@/utils/px2rem";
import styled, { DefaultTheme } from "styled-components";
// import Button from '@/components/Button';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: ${px2rem(32)};

  .content_left {
    overflow: hidden;
    min-width: ${px2rem(283)};
    padding: ${px2rem(32)};
    padding-left: 0;
    border-right: 1px solid #ececed;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 0;
      border: none;
    }

    .file-uploader {
      opacity: 0;
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      min-width: auto;
    }
  }

  .content_right {
    /* width: 80%; */
    flex: 1;
    padding: ${px2rem(32)};
    padding-right: 0;

    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 0;
    }
  }

  .upload-block {
    width: 100%;
    height: calc(100vh - ${px2rem(136)});
    display: flex;
    justify-content: center;
    align-items: center;

    .file-wrapper {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
    }

    .title {
      margin-bottom: ${px2rem(4)};
      padding: 0;
      font-size: ${px2rem(24)};
      line-height: ${px2rem(26)};
      letter-spacing: -0.18px;
      color: #1c1c1c;
    }

    .sub-text {
      color: #898989;

      b {
        color: #1c1c1c;
      }
    }

    .file-uploader2 {
      opacity: 0;
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: ${px2rem(40)};

    .content_left {
      border-right: none;
      width: fit-content;
    }

    .content_left,
    .content_right {
      width: 100%;
      padding: 0;
    }

    .upload_left {
      display: block;
    }

    .upload_content {
      margin-bottom: ${px2rem(24)};
    }
  }
`;

export const UploadFileContainer = styled.div`
  padding: ${px2rem(24)} ${px2rem(32)};
  background-color: ${({ theme }: { theme: DefaultTheme }) => theme.bg2};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${px2rem(40)};
  color: ${({ theme }: { theme: DefaultTheme }) => theme.white};

  .upload_left {
    display: flex;
    gap: ${px2rem(20)};
    align-items: center;
    flex: 1;
  }

  .upload_right {
    position: relative;
    overflow: hidden;
  }

  .upload_title {
    margin-bottom: ${px2rem(8)};
    font-weight: 500;
    font-size: ${px2rem(34)};
    line-height: ${px2rem(44)};
    /* identical to box height, or 129% */

    color: ${({ theme }: { theme: DefaultTheme }) => theme.text2};
  }

  .upload_desc {
    font-weight: 400;
    font-size: ${px2rem(20)};
    line-height: ${px2rem(30)};
    color: ${({ theme }: { theme: DefaultTheme }) => theme.text3};
  }

  .button-sub-text {
    color: rgba(255, 255, 255, 0.7);
  }

  @media screen and (max-width: 768px) {
    display: block;
    padding: ${px2rem(24)};

    .create-btn {
      width: 100%;
    }

    .upload_description {
      margin-bottom: ${px2rem(16)};
    }
  }
`;

export const PreserveButton = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: #1c1c1c;
  padding: ${px2rem(8)};
  border-radius: 8px;
  gap:  ${px2rem(12)};
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .icon_block {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    width: ${px2rem(48)};
    height: ${px2rem(48)};
  }

  .normal_text {
    font-weight: 500;
    font-size: ${px2rem(20)};
    line-height: ${px2rem(26)};
    color: #ffffff;
  }

  .small_text {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.18px;
    color: #ffffff;
  }
`;
