import ContractABIJson from '@/abis/photos.json';
import { PHOTO_CONTRACT } from '@/configs';
import { ERROR_CODE } from '@/constants/error';
import { AssetsContext } from '@/contexts/assets-context';
import { TransactionEventType } from '@/enums/transaction';
import { useContract } from '@/hooks/useContract';
import { ContractOperationHook, DAppType } from '@/interfaces/contract-operation';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { Transaction } from 'ethers';
import { useCallback, useContext } from 'react';
import * as TC_SDK from 'trustless-computer-sdk';

export interface IUploadImageParams {
  listOfChunks: Array<Array<Buffer>>;
  selectFee: number;
  album: string;
}

const useUploadImage: ContractOperationHook<
  IUploadImageParams,
  Transaction | null
> = () => {
  const { account, provider } = useWeb3React();
  const contract = useContract(PHOTO_CONTRACT, ContractABIJson.abi, true);
  const { btcBalance } = useContext(AssetsContext);

  const call = useCallback(
    async (params: IUploadImageParams): Promise<Transaction | null> => {
      if (account && provider && contract) {
        const { listOfChunks, selectFee, album } = params;
        const tcTxSizeByte = listOfChunks.reduce((prev, cur) => prev + Buffer.byteLength(cur ? cur[0] : ''), 0);

        console.log({
          tcTxSizeByte: tcTxSizeByte,
          feeRatePerByte: selectFee,
        });

        const estimatedFee = TC_SDK.estimateInscribeFee({
          tcTxSizeByte: tcTxSizeByte,
          feeRatePerByte: selectFee,
        });

        const balanceInBN = new BigNumber(btcBalance);
        if (balanceInBN.isLessThan(estimatedFee.totalFee)) {
          throw Error(ERROR_CODE.INSUFFICIENT_BALANCE);
        }

        const transaction = await contract
          .connect(provider.getSigner())
          .upload(listOfChunks, album);

        return transaction;
      }

      return null;
    },
    [account, provider, contract, btcBalance],
  );

  return {
    call: call,
    dAppType: DAppType.PHOTO,
    transactionType: TransactionEventType.MINT,
  };
};

export default useUploadImage;
