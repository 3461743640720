import { ROUTE_PATH } from "@/constants/route-path";
import Home from "@/containers/Home";
import Layout from "@/layouts";
import { getAccessToken } from "@/utils/auth-storage";
import { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";

const HomePage: NextPage = () => {
  const router = useRouter();
  const isLogin = getAccessToken();

  useEffect(() => {
    if (!isLogin) {
      router.push(ROUTE_PATH.CONNECT_WALLET);
    }
  }, []);

  if (!isLogin) return null;

  return (
    <Layout>
      <Home />
    </Layout>
  );
};

export default HomePage;
