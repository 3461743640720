import CryptoJS, { enc } from 'crypto-js';

// Encryption function
export const encryptImageWithAes = (image: File, key: string): Promise<string> => {
  const fileReader = new FileReader();
  fileReader.readAsArrayBuffer(image);

  return new Promise((resolve, reject) => {
    fileReader.onload = () => {
      if (!fileReader.result) {
        throw Error();
      }
      const wordArray = CryptoJS.lib.WordArray.create(fileReader.result as any);
      const encrypted = CryptoJS.AES.encrypt(wordArray, key);
      resolve(encrypted.toString());
    };

    fileReader.onerror = () => {
      reject(fileReader.error);
    };
  });
};

// Decryption function
export const decryptImageWithAes = (encryptedImage: string, key: string) => {
  const decrypted = CryptoJS.AES.decrypt(encryptedImage, key);
  const buffer = new Uint8Array(decrypted.words.length * 4);

  for (let i = 0; i < decrypted.words.length; i++) {
    buffer[i * 4 + 0] = (decrypted.words[i] >> 24) & 0xff;
    buffer[i * 4 + 1] = (decrypted.words[i] >> 16) & 0xff;
    buffer[i * 4 + 2] = (decrypted.words[i] >> 8) & 0xff;
    buffer[i * 4 + 3] = decrypted.words[i] & 0xff;
  }

  const blob = new Blob([buffer], { type: 'image/jpeg' });
  const url = URL.createObjectURL(blob);
  return url;
};
