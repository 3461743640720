import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import secretStorage from '@/utils/secret-storage';
import { useAppDispatch } from '@/state/hooks';
import { updateSecretKey } from '@/state/user/reducer';

const SIGNING_MESSAGE = 'Sign this message to generate your Bitcoin Photos secret key. This key will be used for encrypting and decrypting your Bitcoin Photos.';

const useMetaMaskKeyGenerator = () => {
  const dispatch = useAppDispatch()
  const { account, provider } = useWeb3React();

  const generateKey = async (): Promise<string> => {
    try {
      if (!account || !provider) {
        throw new Error('Provider not found.');
      }

      const isGenerating = secretStorage.getUserIsGeneratingSecret();
      if (isGenerating) {
        return '';
      }

      const existingKey = secretStorage.getUserSecret(account);
      if (existingKey) {
        return existingKey;
      }

      secretStorage.setUserIsGeneratingSecret(true);

      const signer = provider.getSigner(account);
      const signature = await signer.signMessage(SIGNING_MESSAGE);
      const hash = ethers.utils.sha256(signature);
      const publicKey = ethers.utils.recoverPublicKey(hash, signature);
      const secretKey = ethers.utils.computePublicKey(
        publicKey,
        true
      ).slice(2);
      secretStorage.setUserSecret(account, secretKey);
      secretStorage.removeUserIsGeneratingSecret();
      dispatch(updateSecretKey(secretKey));
      return secretKey;
    } catch (error: unknown) {
      console.error(error);
      secretStorage.removeUserIsGeneratingSecret();
      throw new Error('Failed to generate a key.');
    }
  }

  return { generateKey };
};

export default useMetaMaskKeyGenerator;