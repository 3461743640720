import useDownloadImage from "@/hooks/contract-operations/photo/useDownloadImage";
import useContractOperation from "@/hooks/contract-operations/useContractOperation";
import {
  getUserSelector,
} from "@/state/user/selector";
import { decryptImageWithAes } from "@/utils/image-encrypter";
import { showError } from "@/utils/toast";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Wrapper, LoadingWrapper } from "./ImageItem.styled";
import Spinner from "@/components/Spinner";
import useMetaMaskKeyGenerator from "@/hooks/useMetamaskGenerator";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

interface IProps {
  photoIndex: string;
}

const ImageItem: React.FC<IProps> = ({ photoIndex }: IProps) => {
  const { run } = useContractOperation({
    operation: useDownloadImage,
    inscribeable: false,
  });
  const user = useSelector(getUserSelector);
  const [imgSrc, setImgSrc] = useState<null | string>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { generateKey } = useMetaMaskKeyGenerator();

  const fetchImageByIndex = async (): Promise<void> => {
    if (!photoIndex) {
      return;
    }

    try {
      const imageChunks = await run({
        photoIndex: photoIndex,
      });
      if (!imageChunks || !imageChunks.length) {
        return;
      }
      const encryptedData = imageChunks[0];
      let secretKey = user?.secretKey;
      if (!secretKey) {
        const genKey = await generateKey();
        if (!genKey) {
          return
        }
        secretKey = genKey;
      }
      const decryptedImage = decryptImageWithAes(
        Buffer.from(encryptedData.slice(2), "hex").toString(),
        secretKey
      );
      setImgSrc(decryptedImage);
    } catch (err: unknown) {
      showError({
        message:
          (err as Error).message ||
          "Something went wrong. Please try again later.",
      });
    }
  };

  useEffect(() => {
    fetchImageByIndex();
  }, [user?.secretKey]);

  return (
    <>
      <Wrapper onClick={() => setIsOpen(true)}>
        {imgSrc ? (
          <img src={imgSrc} alt={photoIndex} />
        ) : (
          <LoadingWrapper>
            <div className="loadingContainer">
              <Spinner />
            </div>
          </LoadingWrapper>
        )}
      </Wrapper>
      <Lightbox
        open={isOpen}
        close={() => setIsOpen(false)}
        plugins={[Zoom]}
        slides={[
          { src: imgSrc || '' },
        ]}
        zoom={{
          maxZoomPixelRatio: 2,
          zoomInMultiplier: 2,
          doubleTapDelay: 300,
          doubleClickDelay: 300,
          doubleClickMaxStops: 2,
          keyboardMoveDistance: 50,
          wheelZoomDistanceFactor: 100,
          pinchZoomDistanceFactor: 100,
          scrollToZoom: true,
        }}
      />
    </>
  );
};

export default ImageItem;
