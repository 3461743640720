import Button from '@/components/Button';
import IconSVG from '@/components/IconSVG';
import Text from '@/components/Text';
import MediaPreview from '@/components/ThumbnailPreview/MediaPreview';
import ToastConfirm from '@/components/ToastConfirm';
import { CDN_URL, TC_URL } from '@/configs';
import { ROUTE_PATH } from '@/constants/route-path';
import { AssetsContext } from '@/contexts/assets-context';
import { DappsTabs } from '@/enums/tabs';
import useUploadImage, {
  IUploadImageParams,
} from '@/hooks/contract-operations/photo/useUploadImage';
import useContractOperation from '@/hooks/contract-operations/useContractOperation';
import { readFileAsBuffer } from '@/utils';
import { walletLinkSignTemplate } from '@/utils/configs';
import { showError } from '@/utils/toast';
import { prettyPrintBytes } from '@/utils/units';
import { formatBTCPrice } from '@trustless-computer/dapp-core';
import { useWeb3React } from '@web3-react/core';
import { Transaction } from 'ethers';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import * as TC_SDK from 'trustless-computer-sdk';
import { StyledModalUpload } from './ModalUpload.styled';
import { ERROR_CODE } from '@/constants/error';
import { encryptImageWithAes, decryptImageWithAes } from '@/utils/image-encrypter';
import useMetaMaskKeyGenerator from '@/hooks/useMetamaskGenerator';
import { DEFAULT_ALBUM } from '@/constants/common';
import { useSelector } from 'react-redux';
import { getUserSelector } from '@/state/user/selector';
import { useAppDispatch } from '@/state/hooks';

type Props = {
  show: boolean;
  handleClose: () => void;
  files: File[] | null;
  setFiles: (file: File[] | null) => void;
};

const ModalUpload = (props: Props) => {
  const router = useRouter();
  const { account } = useWeb3React();
  const { generateKey } = useMetaMaskKeyGenerator();
  const { show = false, handleClose, files, setFiles } = props;
  const [preview, setPreview] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [selectFee, setSelectFee] = useState<number>(0);
  const { feeRate } = useContext(AssetsContext);
  const user = useSelector(getUserSelector);
  const { run } = useContractOperation<IUploadImageParams, Transaction | null>({
    operation: useUploadImage,
  });
  const dispatch = useAppDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const { dAppType, transactionType } = useUploadImage();

  const handleUploadFile = async () => {
    if (!account) {
      router.push(`${ROUTE_PATH.CONNECT_WALLET}?next=${window.location.href}`);
      return;
    }

    if (!files) {
      showError({
        message: "File is required",
      });
      return;
    }

    try {
      setIsProcessing(true);
      let secretKey = user?.secretKey;
      if (!secretKey) {
        secretKey = await generateKey();
      }
      const encryptedData = await encryptImageWithAes(files[0], secretKey);
      const chunks = Buffer.from(encryptedData);
      const tx = await run({
        listOfChunks: [[chunks]],
        album: DEFAULT_ALBUM,
        selectFee,
      });
      toast.success(
        () => (
          <ToastConfirm
            id="create-success"
            url={walletLinkSignTemplate({
              transactionType,
              dAppType,
              hash: Object(tx).hash,
              isRedirect: true,
            })}
            message="Please go to your wallet to authorize the request for the Bitcoin transaction."
            linkText="Go to wallet"
          />
        ),
        {
          duration: 50000,
          position: "top-right",
          style: {
            maxWidth: "900px",
            borderLeft: "4px solid #00AA6C",
          },
        }
      );
      handleClose();
    } catch (err: unknown) {
      if ((err as Error).message === ERROR_CODE.PENDING) {
        showError({
          message:
            "You have some pending transactions. Please complete all of them before moving on.",
          url: `${TC_URL}/?tab=${DappsTabs.TRANSACTION}`,
          linkText: "Go to Wallet",
        });
      } else if ((err as Error).message === ERROR_CODE.INSUFFICIENT_BALANCE) {
        const fileBuffer = await readFileAsBuffer(files[0]);

        const estimatedFee = TC_SDK.estimateInscribeFee({
          tcTxSizeByte: Buffer.byteLength(fileBuffer),
          feeRatePerByte: selectFee,
        });

        showError({
          message: `Your balance is insufficient. Please top up at least ${formatBTCPrice(
            estimatedFee.totalFee.toString()
          )} BTC to pay network fee.`,
          url: `${TC_URL}`,
          linkText: "Go to Wallet",
        });
      } else {
        showError({
          message:
            (err as Error).message ||
            "Something went wrong. Please try again later.",
        });
      }
      console.log(err);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    setSelectFee(feeRate.fastestFee);
  }, [feeRate.fastestFee]);

  return (
    <StyledModalUpload
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      backdrop="static"
    >
      <Modal.Header>
        <IconSVG
          className="cursor-pointer"
          onClick={handleClose}
          src={`${CDN_URL}/icons/ic-close.svg`}
          maxWidth={"22px"}
        />
      </Modal.Header>
      <Modal.Body>
        <h5 className="font-medium">Upload files</h5>
        <div className="preview-table">
          <div className="header d-flex">
            <div className="left">{`File (${files?.length || 0})`}</div>
            <div className="right">Status</div>
          </div>
          <div className="body">
            {Array.from(files || [])?.map?.((file) => (
              <div className="preview-wrapper">
                {/* <div className="delete">
                  <IconSVG
                    src={`${CDN_URL}/icons/trash.svg`}
                    maxWidth={"16"}
                  />
                </div> */}
                <div className="thumbnail-wrapper">
                  <MediaPreview
                    previewExt={file?.name?.split(".")?.pop() || ""}
                    previewUrl={URL.createObjectURL(file)}
                  />
                </div>
                <div className="file-upload-name">
                  <div className="file-name">{file.name}</div>
                  <div className="file-specs">
                    <span>{prettyPrintBytes(file.size)}</span>
                  </div>
                </div>
                <div className="status">
                  {error ? (
                    <span className="error-text">Ready to upload</span>
                  ) : (
                    <div className="d-flex">
                      <IconSVG
                        className="mr-8"
                        src={`${CDN_URL}/icons/check-black.svg`}
                        maxWidth={"24"}
                        color="#00AA6C"
                      />
                      <span className="text">Ready to upload</span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="block-btn">
          <Button
            disabled={isProcessing}
            className="cancel-btn"
            onClick={handleClose}
            background="#ececed"
          >
            <Text size="medium" fontWeight="medium" className="confirm-text">
              Cancel
            </Text>
          </Button>
          {files && !error && (
            <Button
              disabled={isProcessing}
              className="confirm-btn"
              onClick={handleUploadFile}
              background="#1c1c1c"
            >
              <Text size="medium" fontWeight="medium" className="confirm-text">
                {isProcessing ? "Processing..." : "Upload"}
              </Text>
            </Button>
          )}
        </div>
      </Modal.Body>
    </StyledModalUpload>
  );
};

export default ModalUpload;
