import px2rem from "@/utils/px2rem";
import { Modal } from "react-bootstrap";
import styled, { DefaultTheme } from "styled-components";

export const StyledModalUpload = styled(Modal)`
  &.modal {
    --bs-modal-color: ${({ theme }) => theme.bg1};
  }

  .modal-content {
    border-radius: 2px;
  }

  .modal-header {
    border-bottom: none;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    padding-top: ${px2rem(18)};
    padding-right: ${px2rem(18)};
  }

  .modal-body {
    padding: ${px2rem(32)};
    padding-top: ${px2rem(7)};
  }

  .modal-footer {
    border-top: none;
  }

  /* ======= Custom modal ========== */

  .dropZone {
    margin-top: ${px2rem(24)};

    width: 100%;
  }

  .font-medium {
    color: #1c1c1c;
  }

  .preview-table {
    margin: ${px2rem(20)} 0;
    display: flex;
    flex-direction: column;
    border: 1px solid #ececed;
    border-radius: 8px;

    .header {
      padding: ${px2rem(12)} ${px2rem(24)};
      background: #f4f4f7;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #1c1c1c;

      .left {
        width: 70%;
      }

      .right {
        width: 30%;
        padding-left: ${px2rem(16)};
      }
    }

    .body {
      padding: ${px2rem(12)} 0;
      display: flex;
      flex-wrap: wrap;
      color: #1c1c1c;
    }
  }

  .preview-wrapper {
    width: 100%;
    margin-bottom: ${px2rem(12)};
    display: flex;

    .delete {
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .status {
      width: 30%;
      display: flex;
      align-items: center;
      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
    }

    .thumbnail-wrapper {
      width: ${px2rem(83.76)};
      position: relative;

      img {
        max-height: ${px2rem(48)};
        width: 100%;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .file-upload-name {
      width: calc(70% - ${px2rem(83.76)});
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .file-name {
        max-width: calc(100% - 32px);
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        font-size: ${px2rem(14)};
        line-height: ${px2rem(22)};
        color: #1c1c1c;
      }

      .file-specs {
        span {
          padding: 0 ${px2rem(6)};
          display: inline-block;
          border-radius: 4px;
          background: #f4f4f6;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          text-transform: lowercase;
          color: #5b5b5b;
        }
      }
    }
  }

  .error-text {
    color: ${({ theme }: { theme: DefaultTheme }) => theme.text6};
  }

  .upload-fee {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${px2rem(16)};
    padding: ${px2rem(16)};
    background-color: ${({ theme }: { theme: DefaultTheme }) => theme.bg5};
    border: 1px solid;
    border-color: ${({ theme }: { theme: DefaultTheme }) => theme.text2};
    margin-bottom: ${px2rem(40)};

    p {
      color: ${({ theme }: { theme: DefaultTheme }) => theme.text7};
    }
  }

  .mr-8 {
    margin-right: ${px2rem(8)};
  }

  .mb-8 {
    margin-bottom: ${px2rem(8)};
  }

  .est-fee {
    margin-bottom: ${px2rem(28)};
  }

  .est-fee-options {
    display: flex;
    align-items: center;
    gap: ${px2rem(10)};
  }

  .est-fee-item {
    flex: 1;
    padding: ${px2rem(8)} ${px2rem(16)};
    border: 1px solid #ececed;
    border-radius: 8px;
    display: grid;
    place-items: center;
    text-align: center;
    opacity: 0.6;

    &.active {
      opacity: 1;
      border-width: 1px;
      border-color: #1c1c1c;
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
  .ext-price {
    font-size: ${px2rem(14)};
    span {
      font-size: ${px2rem(12)};
    }
  }

  .block-btn {
    margin-top: ${px2rem(32)};
    display: flex;
    justify-content: flex-end;

    .cancel-btn,
    .confirm-btn {
      border-radius: 8px !important;
    }

    .cancel-btn {
      padding: ${px2rem(15)} ${px2rem(25)};

      .confirm-text {
        color: #1c1c1c;
      }
    }

    .confirm-btn {
      margin-left: ${px2rem(12)};
      padding: ${px2rem(15)} ${px2rem(48)};
    }
  }
`;
