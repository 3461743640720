import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { Container, Grid, SecretWrapper, SignWalletButton } from "./ImageList.styled";
import ImageItem from "../ImageItem";
import useContractOperation from "@/hooks/contract-operations/useContractOperation";
import useGetListAlbumPhotos, {
  IGetListAlbumPhotosParams,
} from "@/hooks/contract-operations/photo/useGetListAlbumPhotos";
import { DEFAULT_ALBUM } from "@/constants/common";
import { useSelector } from "react-redux";
import { getIsAuthenticatedSelector, getUserSelector } from "@/state/user/selector";
import useMetaMaskKeyGenerator from "@/hooks/useMetamaskGenerator";
import { showError } from "@/utils/toast";
import { CDN_URL } from "@/configs";

const ImageList = ({ children }: { children?: React.ReactNode }) => {
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);
  const user = useSelector(getUserSelector);
  const [isFetching, setIsFetching] = useState(false);
  const [images, setImages] = useState<Array<string>>([]);
  const { run } = useContractOperation<
    IGetListAlbumPhotosParams,
    Array<string>
  >({
    operation: useGetListAlbumPhotos,
    inscribeable: false,
  });
  const { generateKey } = useMetaMaskKeyGenerator();
  const [isGenerating, setIsGenerating] = useState(false);

  const fetchUserListAlbumPhotos = async (): Promise<void> => {
    if (isFetching) {
      return;
    }

    try {
      setIsFetching(true);
      const data = await run({
        album: DEFAULT_ALBUM,
      });
      setImages(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsFetching(false);
    }
  };

  const handleGenerateKey = async () => {
    if (isGenerating) {
      return
    }

    try {
      setIsGenerating(true);
      await generateKey();
    } catch (err: unknown) {
      console.log(err);
      showError({
        message: (err as Error).message || 'Something went wrong.'
      })
    } finally {
      setIsGenerating(false);
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserListAlbumPhotos();
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <></>;
  }

  if (!user?.secretKey) {
    return (
      <SecretWrapper>
        <img src={`${CDN_URL}/icons/secret-ic.svg`} alt="secret-ic" />
        <p className="secretDescription">Generate secret to see images</p>
        <SignWalletButton  disabled={isGenerating} onClick={handleGenerateKey}>
          {isGenerating ? 'Signing...' : 'Sign wallet'}
        </SignWalletButton>
      </SecretWrapper>
    );
  }

  return (
    <Container>
      <div className="content">
        {images &&
          images.length > 0 ? (
          <InfiniteScroll
            className="list"
            dataLength={images?.length || 0}
            hasMore={true}
            loader={
              isFetching && (
                <div className="loading">
                  <Spinner animation="border" variant="primary" />
                </div>
              )
            }
            next={() => { }}
          >
            <Grid>
              {images.map((photoIndex: string) => {
                return <ImageItem key={photoIndex} photoIndex={photoIndex} />;
              })}
            </Grid>
          </InfiniteScroll>
        ) : (
          <>
            {children}
          </>
        )}
      </div>
    </Container>
  );
};

export default ImageList;
