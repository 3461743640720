import ContractABIJson from '@/abis/photos.json';
import { PHOTO_CONTRACT } from '@/configs';
import { TransactionEventType } from '@/enums/transaction';
import { useContract } from '@/hooks/useContract';
import { ContractOperationHook, DAppType } from '@/interfaces/contract-operation';
import { useWeb3React } from '@web3-react/core';
import { Transaction } from 'ethers';
import { useCallback } from 'react';

export interface IDownloadImageParams {
  photoIndex: string;
}

const useDownloadImage: ContractOperationHook<
  IDownloadImageParams,
  string | null
> = () => {
  const { account, provider } = useWeb3React();
  const contract = useContract(PHOTO_CONTRACT, ContractABIJson.abi, true);

  const call = useCallback(
    async (params: IDownloadImageParams): Promise<string | null> => {
      if (account && provider && contract) {
        const { photoIndex } = params;
        const transaction = await contract
          .connect(provider)
          .download(photoIndex, {
            from: account,
          });

        return transaction;
      }

      return null;
    },
    [account, provider, contract],
  );

  return {
    call: call,
    dAppType: DAppType.PHOTO,
    transactionType: TransactionEventType.MINT,
  };
};

export default useDownloadImage;
