import { ContractOperationHook, DAppType } from '@/interfaces/contract-operation';
import { useContract } from '@/hooks/useContract';
import ContractAbiJSON from '@/abis/photos.json';
import { PHOTO_CONTRACT } from '@/configs';
import { useWeb3React } from '@web3-react/core';
import { useCallback } from 'react';
import { TransactionEventType } from '@/enums/transaction';

export interface IGetListAlbumPhotosParams {
  album: string;
}

const useGetListAlbumPhotos: ContractOperationHook<IGetListAlbumPhotosParams, Array<string>> = () => {
  const { account, provider } = useWeb3React();
  const contract = useContract(PHOTO_CONTRACT, ContractAbiJSON.abi, false);

  const call = useCallback(
    async (params: IGetListAlbumPhotosParams): Promise<Array<string>> => {
      if (account && provider && contract) {
        const { album } = params;
        const listPhotoIndexes: Array<number> = await contract.connect(provider).listAlbumPhotos(album, {
          from: account
        });
        if (listPhotoIndexes && listPhotoIndexes.length > 0) {
          return listPhotoIndexes.map((indexInBN) => indexInBN.toString());
        }
      }

      return [];
    },
    [account, provider, contract],
  );

  return {
    call,
    dAppType: DAppType.PHOTO,
    transactionType: TransactionEventType.NONE,
  };
};

export default useGetListAlbumPhotos;
