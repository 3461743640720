import styled, { DefaultTheme } from "styled-components";
import px2rem from "@/utils/px2rem";

export const Wrapper = styled.div`
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%; /* 9:16 aspect ratio */
  border: 1px solid #c0c0c0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;

  .loadingContainer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
